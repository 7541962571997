import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

import Can from '@/components/Can';
import * as V from '@/components/views';
import { Spinner } from '@/components/Spinner';
import { Dashboard } from '@/components/Dashboard';
import { Account } from '@/components/Settings/Account';
import { ManageUsers, Preferences } from '@/components/Settings';
import { Organizations } from '@/components/Settings/Organizations';
import { ContractPlans } from '@/components/Settings/ContractPlans';

const Users = lazy(() => import('@/views/Users'));
const Reports = lazy(() => import('@/views/Reports'));
const ListReport = lazy(() => import('@/views/ListReport'));
const CreateReport = lazy(() => import('@/views/CreateReport'));
const Rosa = lazy(() => import('@/views/ListReport/Report/Rosa'));
const Reba = lazy(() => import('@/views/ListReport/Report/Reba'));
const Rula = lazy(() => import('@/views/ListReport/Report/Rula'));
const Owas = lazy(() => import('@/views/ListReport/Report/Owas'));
const Niosh = lazy(() => import('@/views/ListReport/Report/Niosh'));
const AngleTime = lazy(() => import('@/views/ListReport/Report/AngleTime'));
const StrainIndex = lazy(() => import('@/views/ListReport/Report/StrainIndex'));
const KimPushPull = lazy(() => import('@/views/ListReport/Report/KimPushPull'));
const LibertyMutual = lazy(() => import('@/views/ListReport/Report/LibertyMutual'));
const KimManualHandling = lazy(() => import('@/views/ListReport/Report/KimManualHandling'));
const Files = lazy(() => import('@/components/views/Files'));

const reportRoutes = [
	{
		type: 'niosh',
		component: Niosh
	},
	{
		type: 'rula',
		component: Rula
	},
	{
		type: 'rosa',
		component: Rosa
	},
	{
		type: 'reba',
		component: Reba
	},
	{
		type: 'owas',
		component: Owas
	},
	{
		type: 'angle-time',
		component: AngleTime
	},
	{
		type: 'strain-index',
		component: StrainIndex
	},
	{
		type: 'kim-pp',
		component: KimPushPull
	},
	{
		type: 'kim-mho',
		component: KimManualHandling
	},
	{
		type: 'liberty-mutual',
		component: LibertyMutual
	},
	{
		type: 'preliminary-ergonomic-analysis',
		component: V.PreliminaryAnalysisForm
	}
];

export function PrivateRoutes() {
	return (
		<Route
			component={() => (
				<Suspense fallback={<Spinner />}>
					<Route exact path="/" component={Dashboard} />
					<Route exact path="/users" component={Users} />
					<Route exact path="/upload" component={V.Upload} />
					<Route exact path="/files" component={Files} />
					<Route exact path="/reporting" component={Reports} />
					<Route exact path="/settings" component={Account} />
					<Route exact path="/settings/account" component={Account} />
					<Route exact path="/action-plans" component={V.ActionPlans} />
					<Route exact path="/super-pea/new-document" component={V.MergePea} />
					<Route exact path="/super-pea/file-history" component={V.SuperPreliminaryAnalysis} />
					<Route path="/reporting/list/:report_name/:custom_report_id?" component={ListReport} />
					<Route exact path="/reporting/create/:report_name/:custom_report_id?" component={CreateReport} />
					<Route
						exact
						path="/reporting/personalised-report/:report_id/:file_id?"
						component={V.PersonalisedReport}
					/>

					{reportRoutes.map((report) => (
						<Route
							key={report.type}
							component={report.component}
							path={`/reporting/${report.type}/:file_id`}
						/>
					))}
					{Can('settings', 'management') && (
						<>
							<Route
								exact
								path="/settings/companies"
								component={process.env.REACT_APP_CONTRACT_PLANS ? ContractPlans : Organizations}
							/>
							<Route exact path="/settings/preferences" component={Preferences} />
							<Route exact path="/settings/manage-users" component={ManageUsers} />
							<Route exact path="/settings/personalised-reports" component={V.PersonalisedReportsList} />
							<Route
								exact
								path="/settings/personalised-reports/manage/:report_id?"
								component={V.PersonalisedReportsManager}
							/>
						</>
					)}
				</Suspense>
			)}
		/>
	);
}
